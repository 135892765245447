<template>
<div>
    <b-row>
        <b-col cols="12">
            <b-card class="my-card m-0">
                <b-table
                striped
                small
                id="myTables"
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                :items="allowances"
                :fields="fields"
                @row-dblclicked="editForm"
                v-hotkey="{ 'insert' : createAllowance, 'enter' : editForm,}"
                @row-clicked="clicked"
                head-row-variant="secondary"
                @row-contextmenu="handleContextMenuEvent"
                >
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                </template> 
                <template #cell(prices)="data">
                    <div v-for="prices in data.item.prices" :key="prices.index">
                        {{ `${prices.count} = ${prices.price}` }}
                    </div>
                </template>
                <template #cell(order_types)="data">
                    <span v-for="types in data.item.order_types" :key="types.index">
                        {{ types }}
                    </span>
                </template>
                <template #cell(addr_type_relation)="data">
                    {{ data.item.addr_type_relation = 1 ? "Да" : "Не" }}
                </template> 
                <template #cell(discount_show)="data">
                    {{ data.item.discount_show = 1 ? "Да" : "Не" }}
                </template> 
                <template #cell(is_min_price)="data">
                    {{ data.item.is_min_price = 1 ? "Да" : "Не" }}
                </template> 
                <template #cell(add_min_price)="data">
                    {{ data.item.add_min_price = 1 ? "Да" : "Не" }}
                </template> 
                <template #cell(is_require)="data">
                    {{ data.item.is_require = 1 ? "Да" : "Не" }}
                </template> 
                <template #cell(require_parameter)="data">
                    {{ data.item.require_parameter = 1 ? "Да" : "Не" }}
                </template> 
                <template #cell(replace_base_price_order)="data">
                    {{ data.item.replace_base_price_order = 1 ? "Да" : "Не" }}
                </template> 
            </b-table>
            <vue-context ref="menu">
                    <li>
                        <b-link
                        class="d-flex align-items-center"
                        @click="createAllowance"
                        >
                        <feather-icon
                            icon="PlusIcon"
                            size="16"
                        />
                        <span class="ml-75">Добавить</span>
                        <span class="text_INS">Ins</span>
                        </b-link>
                    </li>
                    <li>
                        <b-link
                        class="d-flex align-items-center"
                        @click="editForm"
                        >
                        <feather-icon
                            icon="EditIcon"
                            size="16"
                        />
                        <span class="ml-75">Изменить</span>
                        <span class="text_enter">Enter</span>
                        </b-link>
                    </li>
                    <li>
                        <b-link
                        class="d-flex align-items-center"
                        @click="deleteAllowance"
                        >
                        <feather-icon
                            icon="CalendarIcon"
                            size="16"
                        />
                        <span class="ml-75">Удалить</span>
                        <span class="text_ALT">Delete</span>
                        </b-link>
                    </li>
                    <hr>
                    <li>
                        <b-link
                            class="d-flex align-items-center"
                            @click="autoUpdate"
                            >
                            <img :src="iconChect">
                            <span class="ml-1">Автообновление</span>
                        </b-link>
                    </li>
                </vue-context>
        </b-card>
    </b-col>
    </b-row>
</div>
    
</template>
<script>
import VueContext from 'vue-context'
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
export default {
    components: {
        VueContext
    },
    props: ['allowances'],
    data(){
        return {
            id: null,
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '50px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '50px'}},
                { key: 'prices', label: 'Цена', sortable: true, thStyle: {width: '150px'}},
                { key: 'order_types', label: 'Типы заказов', sortable: true, thStyle: {width: '150px'}},
                { key: 'user_login', label: 'Логин', sortable: true, thStyle: {width: '150px'}},
                { key: 'user_id', label: 'ID логин', sortable: true, thStyle: {width: '150px'}},
                { key: 'pattern_allowance_name', label: 'Имя шаблона доступа', sortable: true, thStyle: {width: '150px'}},
                { key: 'pattern_allowance_id', label: 'ID шаблона доступа', sortable: true, thStyle: {width: '150px'}},
                { key: 'addr_type_relation', label: 'Добавление типа реляции', sortable: true, thStyle: {width: '150px'}},
                { key: 'discount_show', label: 'Скидка', sortable: true, thStyle: {width: '150px'}},
                { key: 'is_min_price', label: 'Минимальная цена', sortable: true, thStyle: {width: '150px'}},
                { key: 'add_min_price', label: 'Добавить минимальную цену', sortable: true, thStyle: {width: '150px'}},
                { key: 'is_require', label: 'Требуется', sortable: true, thStyle: {width: '150px'}},
                { key: 'require_parameter', label: 'Требуемый параметр', sortable: true, thStyle: {width: '150px'}},
                { key: 'replace_base_price_order', label: 'Замена основной цены заказ', sortable: true, thStyle: {width: '150px'}},
            ],
            is_active: false,
            iconChect: require('@/assets/images/icons/square.svg'),
            iconsSquare: require('@/assets/images/icons/square.svg'),
            iconsCheck: require('@/assets/images/icons/checkSquareIcon.svg')
        }
    },
    mounted(){
        resizeable()
    },
    methods:{
        editForm(){
            this.$emit('editForm', this.id)
        },
        createAllowance() {
            this.$bvModal.show(this.$route.name + 'CreateModal')
        },
        deleteAllowance() {
            this.$http.delete(`/v2/allowances/${this.id}`)
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Надбавка удалена!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.$emit('refresh');
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Не удалось удалить!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors, 
                        },
                    })
                })
        },
        handleContextMenuEvent(item, index, event){
           event.preventDefault();
           this.$refs.menu.open(event)
           this.id = item.id
        }, 
        autoUpdate() {
            this.iconChect = this.is_active ? this.iconsSquare : this.iconsCheck;
            this.is_active = !this.is_active;
            this.$emit('timerAfto', this.is_active)
        },
        clicked(item, index, event){
            this.id = item.id
            this.$store.state.filterPlus.tableIndex = index

            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}  
.text_INS {
    margin-left: 90px;
}
.text_enter {
    margin-left: 75px;
}
.text_ALT {
    margin-left: 80px;
}
.text-Update {
    margin-left: 27px;
}
</style> 